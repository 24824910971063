/**
 * Created by workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 09/05/24
 * Time: 1:09 PM
 * Project: W-CRM
 */
.subscription-alert-container {
    width: 100%;
}