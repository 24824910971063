.alert-box {
  border-radius: var(--primary-border-radius);
  padding: 1rem;
  font-size: var(--default-font);
  line-height: 1.25rem;
  text-align: start;
}
.alert-icon {
  height: 1.25rem;
}
.alert-icon-box {
  flex-shrink: 0;
}
.alert-message-box {
  margin-left: 0.75rem;
}
.alert-title {
  font-weight: 500;
  margin-bottom: 0.5rem; /* 8px */
}
.alert-description {
  /*margin-top: 0.5rem; !* 8px *!*/
}

.alert-warning {
  background-color: var(--yellow-50);
}
.alert-warning .alert-title {
  color: var(--yellow-800);
}
.alert-warning .alert-description {
  color: var(--yellow-700);
}
.alert-warning .alert-icon {
  color: var(--yellow-400);
}

.alert-error {
  background-color: var(--red-50);
}
.alert-error .alert-title {
  color: var(--red-800);
}
.alert-error .alert-description {
  color: var(--red-700);
}
.alert-error .alert-icon {
  color: var(--red-400);
}

.alert-info {
  background-color: var(--blue-50);
}
.alert-info .alert-title {
  color: var(--blue-800);
}
.alert-info .alert-description {
  color: var(--blue-700);
}
.alert-info .alert-icon {
  color: var(--blue-400);
}

.alert-success {
  background-color: var(--green-50);
}
.alert-success .alert-title {
  color: var(--green-800);
}
.alert-success .alert-description {
  color: var(--green-700);
}
.alert-success .alert-icon {
  color: var(--green-400);
}


.alert-componentWrapper-container {
  all: inherit;
  position: relative;
}
.alert-componentWrapper-overlay {
  background: rgba(255, 255, 255, 0.55);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--primary-padding);
  color: var(--primary-dark);
  backdrop-filter: blur(3px);
}
