/**
* Created by WorkAxis www.workaxis.in
* Developer: Padma Dev.E
* Developer Contact: padmadev.e@workaxis.in
* Admin: shyam@workaxis.in
* Date: 07/11/22
* Time: 7:11 PM
* Project: W-CRM
*/
.modern-select-input-wrapper {
    flex: 1;
}

.modern-select-input {
    flex: 1;
    border: 1px solid var(--gray200);
    border-radius: var(--primary-border-radius);
    transition: background-color 0.5 ease-in-out;
    width: 100%;
    cursor: pointer;
}

/*.modern-select-holder::after {*/
/*  content: "▾";*/
/*  position: absolute;*/
/*  top: 0;*/
/*  line-height: 2.2rem;*/
/*  right: 1rem;*/
/*  color: var(--primary);*/
/*}*/
.modern-select-input:focus {
    outline: none !important;
    border: 1px solid var(--gray700);
}

.modern-select-input:disabled {
    cursor: not-allowed;
}

.modern-select-holder {
    position: relative;
}

.modern-select-list-holder {
    background: var(--primary-bg);
    z-index: 1;
    width: 100%;
    gap: 3px;
    display: flex;
    flex-direction: column;
}

.modern-select-list-holder li {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    cursor: pointer;
}

.modern-select-list-holder li:hover {
    background: var(--gray50);
}

.modern-select-list-container {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.modernSelect-arrow-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modern-select-input-no-border {
    border: none;
    outline: none;
}
