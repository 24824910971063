/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/31/2022
 * Time: 12:52 PM
 * Project: w_crm
 */

/*.tooltip-parent {*/
/*    position: relative;*/
/*}*/

/*.tooltip-parent .tooltip-child {*/
/*    position: fixed;*/
/*    z-index: 9999 !important;*/
/*    !*bottom: 0;*!*/
/*    padding: 4px 10px;*/
/*    background-color: #060606;*/
/*    color: #e5e5e5;*/
/*    border-radius: var(--primary-border-radius);*/
/*    box-shadow: 0 5px 10px -5px #060606;*/
/*    display: none;*/
/*    transition: opacity ease-in-out 0.2s;*/
/*    transform-origin: bottom;*/

/*    max-width: 400px;*/
/*    white-space: pre-wrap; !* Allows wrapping on word breaks *!*/
/*    word-wrap: break-word; !* Ensure long words are broken *!*/
/*    overflow-wrap: break-word; !* Handles non-ASCII characters *!*/
/*}*/

/*.tooltip-parent .tooltip-child::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 10px;*/
/*    aspect-ratio: 1;*/
/*    background-color: #060606;*/
/*    border-top-left-radius: 100%;*/
/*    bottom: -5px;*/
/*    left: 50%;*/
/*    transform: translateX(-50%) rotate(45deg);*/
/*    -ms-transform: translateX(-50%) rotate(45deg);*/
/*    -webkit-transform: translateX(-50%) rotate(45deg);*/
/*}*/

/*.tooltip-parent:hover .tooltip-child {*/
/*    display: revert;*/
/*}*/

.react-tooltip {
    border-radius: var(--primary-border-radius) !important;
    padding: 4px 10px !important;
    color: #e5e5e5 !important;
    background-color: #060606 !important;
    font-size: var(--default-font) !important;
    z-index: 1000;
}

