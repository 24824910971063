/**
* Created by WorkAxis www.workaxis.in
* Developer: Padma Dev.E
* Developer Contact: padmadev.e@workaxis.in
* Admin: shyam@workaxis.in
* Date: 07/11/22
* Time: 6:23 PM
* Project: W-CRM
*/

.search-input-holder {
  flex: 1;
  position: relative;
}

.search-prefix-icon {
  position: absolute;
  top: 13px;
  right: 15px;
  color: var(--gray700);
}

.search-input {
  display: inline;
  border: 2px solid var(--gray200);
  border-radius: 100px;
  transition: background-color 0.5 ease-in-out;
  width: 100%;
  padding: 10px 30px 10px 15px;
}

.search-input:focus {
  outline: none !important;
  border: 2px solid var(--gray700);
}
