/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/2/2022
 * Time: 4:52 PM
 * Project: w_crm
 */

.button-component {
  border-radius: var(--primary-border-radius);
  font-size: var(--small-font);
}

button:active {
  transform: scale(0.98);
  -ms-transform: scale(0.98);
  -webkit-transform:scale(0.98);
}

.button-component-success-btn {
  background-color: var(--green900);
  color: var(--whiteSmoke100);
}

.button-component-selected-btn {
  background-color: var(--orange100);
  color: var(--orange700);
}

.button-component-non-selected-btn {
  background-color: var(--whiteSmoke50);
  color: var(--primary-dark);
  border: 2px solid var(--primary-dark);
}

.button-component-error-btn {
  background-color: var(--red-700) !important;
  color: var(--whiteSmoke100) !important;
}

.button-component-normal-btn {
  background-color: var(--primary);
  color: var(--secondary);
}

.button-component-outline-btn {
  background-color: transparent;
  color: var(--primary-dark) !important;
  box-shadow: none;
  border: 1px solid var(--primary-dark);
  border-radius: 50px;
}

.button-component-square-btn {
  /*background-color: transparent;*/
  /*color: var(--primary-dark) !important;*/
  box-shadow: none;
  border: 1px solid var(--primary-dark);
  height: 100%;
}

.button-component-primary-btn {
  background-color: var(--primary);
  color: var(--white);
}

.button-component-primary-btn:hover {
  background-color: var(--primary-dark);
}

.button-component-secondary-btn {
  background-color: var(--secondary-light);
  color: var(--white);
}

.button-component-secondary-btn:hover {
  background-color: var(--secondary);
}

.button-component-link-btn {
  color: var(--primary-light) !important;
  background: transparent;
  box-shadow: none !important;
}
