/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 5/17/2022
 * Time: 10:24 AM
 * Project: w_crm
 */
:root {
  --list-container-list-control-width: 5cm;
}

.listRootContainerHolder {
  width: 100%;
  /*height: 100%;*/
  /*background: red;*/
  /*max-height: 40em;*/
  background-color: var(--white);
  position: relative;
  min-height: 30em;
  border-radius: 1em;
}

.listContainerHolder {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listContainerControlHolder {
  display: flex;
  flex-direction: row;
  /*padding: 2em;*/
  padding-top: 1em;
  justify-content: start;
  /*background-color: blue;*/
}

.list-container-tab-control-big-screen {
  display: none;
}

.list-container-tab-control-small-screen {
  display: block;
  width: 100%;
}

.list-container-tab-control-small-screen-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*padding-left: 2em;*/
  /*padding-right: 2em;*/
  align-items: center;
}

@media only screen and (min-width: 780px) {
  .list-container-tab-control-big-screen {
    display: flex;
  }

  .list-container-tab-control-small-screen {
    display: none;
  }
}

.listContainerContentHolder {
  flex: 1;
  padding: 1em;
  overflow-y: auto;
  max-height: 30em;
}

.CommonListItemHolder {
  display: flex;
  margin-top: 10px;
  cursor: pointer;
  justify-content: space-between;
  height: 2cm;
  font-size: medium;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.CommonListItemHolderIcon {
  height: 1.7cm;
  width: 1.7cm;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CommonListItemHolderDetailsSlot {
  padding-left: 10px;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  height: calc(100% - 20px);
}

.CommonListItemHolderDetailsSlot p {
  font-weight: 600;
}

.emptyLostHolder {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--primary-light);
}

.CommonListItemHolderControlSlot {
  display: none;
  padding: 6px;
}

@media (min-width: 780px) {
  .listRootContainerHolder {
    height: calc(100% - 5em);
    max-height: calc(100% - 5em);
  }

  .CommonListItemHolderControlSlot {
    display: flex;
  }
}

.list-item-container-heading {
  font-size: var(--heading-font);
  font-weight: bold;
}

.list-item-container-default {
  font-size: var(--subheading-font);
}

.list-item-holder {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /*overflow: auto;*/
}

.list-item-data-sub {
  padding-top: 5px;
}

.list-item-parent-container {
  /*height: 4rem;*/
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
  /*background: red;*/
  padding: 10px 6px;
  border-bottom: 1.5px solid var(--gray50);
  /*padding-bottom: 1rem;*/
  /*padding-bottom: 1rem;*/
}

.list-item-icon-holder {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;

  /*color: var(--blue700);*/
}

.list-item-data-holder {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 1rem;
  /*padding-top: 7px;*/
  gap: 4px;
}

.list-item-control-holder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .list-item-control-holder {
    flex: 1;
    max-width: 5rem;
  }
}

.list-item-container-horizontal {
  display: flex;
  gap: 1rem;
  height: 100%;
}

.list-item-container-item-horizontal {
  display: flex;
  gap: 3px;
  height: 100%;
}

.list-select-checkbox {
  width: 1.3em;
  height: 1.3em;
  margin: 10px;
}
