/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 02/09/22
 * Time: 12:30 PM
 * Project: W-CRM
 */
.private-layout-parent-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: var(--layout-bg);
    /*max-width: 1900px;*/
    align-items: center;
}

.private-layout-action-fixed {
    position: sticky;
    top: 0;
}

.private-container-action-holder {
    width: 100%;
    height: 3.5rem;
    display: flex;
    padding-right: var(--primary-padding);
    z-index: 4;
    justify-content: center;
}


.private-layout-child-body {
    flex: 1;
    overflow: auto;
    width: 100%;
    max-width: var(--max-width-size);
}

.private-layout-child-body-footer {
    display: flex;
    justify-content: center;
    height: 1.7em;
    align-items: center;
    background: var(--white);
    width: 100%;
}


/* Code Here For Mobile Screen  */

.private-layout-child-body.normalPadding, .private-layout-child-body.LeftPadding, .private-layout-child-body.RightPadding {
    padding-left: 1rem;
    padding-right: 1rem;
}

/* Below 425px Mobile */
/* 425px - 768px Tablet */
@media (min-width: 425px) {
    /* Code Here For Tablet Screen  */
}

/* Above 768px Desktop */
@media (min-width: 768px) {
    /* Code Here For Desktop Screen  */
    .private-layout-child-body.normalPadding {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .private-layout-child-body.LeftPadding {
        padding-left: 1rem;
        padding-right: 0;
    }

    .private-layout-child-body.RightPadding {
        padding-right: 1rem;
        padding-left: 0;
    }
}
