.modal-section {
  position: fixed;
  inset: 0;
  overflow-y: auto;
  z-index: -1000;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.modal-section.active{
  z-index: 9999;
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
.modal-section.blur{
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.2);
}

.modal {
  display: flex;
  min-height: 100vh;
  text-align: center;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  inset: 0px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.7;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  z-index: 100;
}
.modal .content {
  width: 100%;
  position: relative;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-color: rgb(209 213 219);
  display: inline-block;
  vertical-align: bottom;
  background-color: #fff;
  border-radius: 0.5rem;
  text-align: left;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  z-index: 101;
  margin: 10px;
  scale: 0;
  transition: all 400ms ease-in-out;
}
.modal .content.active {
  scale: 1;
  transition: all 400ms ease-in-out;
}
.modal .content-xlarge {
  max-width: 72rem; /* 1152px */
}
.modal .content-large {
  max-width: 48rem; /* 768px */
}
.modal .content-default {
  max-width: 32rem; /* 512px */
}

.modal-header {
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem; /* 8px */
  padding-top: 0.75rem; /* 20px */
}
.model-title-section {
  display: flex;
  gap: 0.5rem; /* 8px */
  align-items: center;
}
.model-title {
  font-size: var(--heading-font);
  line-height: 1.5rem; /* 24px */
  font-weight: 500;
}
.modal .close {
  height: 1.75rem; /* 28px */
  text-align: right;
}
.modal .close-icon {
  height: 1.5rem; /* 24px */
  width: 1.5rem;
}
.modal-body {
  padding: 1rem;
  border-top: 1px solid #d1d5db;
}
.modal-footer {
  padding: 1rem 1rem 0.5rem;
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
}

@media only screen and (min-width: 768px) {
  .modal .content {
    margin-top: 2rem; /* 32px */
    margin-bottom: 2rem; /* 32px */
    vertical-align: middle;
  }
  .modal .modal-header {
    padding-top: 1.25rem;
    padding-left: 1.5rem; /* 16px */
    padding-right: 1.5rem; /* 16px */
  }
  .modal-body {
    padding: 1.5rem;
  }
  .modal-footer {
    padding: 1.5rem;
  }
}



.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 3000ms, transform 3000ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 3000ms, transform 3000ms;
}
