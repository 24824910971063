/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 22/07/22
 * Time: 3:36 PM
 * Project: W-CRM
 */

.menu-items-holder-big-screen {
    display: none;
    position: relative;
    z-index: 10;
}

.navigation-right-nav-container {
    z-index: 10;
}

.menu-items-holder-mobile-screen-hamburger {
    z-index: 2;
}

.menu-items-holder-mobile-screen {
    display: flex;
    position: relative;
    flex-direction: column;
}

.menu-items-holder-mobile-screen-list-holder {
    position: absolute;
    top: 2.8em;
    right: 2rem;
    z-index: 3;
    border-radius: 12px;
}

.menu-items-holder-mobile-screen-list-item-slot {
    height: 3em;
    width: 12rem;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 0.5em;
}

.menu-items-holder-non-res {
    display: flex;
}

@media only screen and (min-width: 1000px) {
    .menu-items-holder-big-screen {
        display: flex;
    }

    .menu-items-holder-mobile-screen {
        display: none;
    }
}

.filter-model-parent-container {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
