.bg-black {
    background-color: black;
}

.bg-white {
    background-color: white;
}

.white-text {
    color: white;
}

.black-text {
    color: black;
}

.blue-text {
    color: var(--blue700);
}

.orange-text {
    color: var(--orange700);
}

.violet-text {
    color: var(--violet700);
}

.green-text {
    color: var(--green700);
}

.gray-text {
    color: var(--gray700);
}

.red-text {
    color: var(--red-700) !important;
}

.bg-blue {
    background-color: var(--blue100);
}

.bg-orange {
    background-color: var(--orange100);
}

.bg-violet {
    background-color: var(--violet100);
}

.bg-green {
    background-color: var(--green100);
}

.bg-gray {
    background-color: var(--gray100);
}

.bg-red {
    background-color: var(--red100) !important;
}

.bg-milk-white {
    background-color: var(--whiteSmoke100);
}
.bg-white {
    background-color: var(--white);
}
.bg-gray-light {
    background-color: var(--gray50);
}

.success-set {
    background-color: var(--green100);
    color: var(--green700);
}

.orange-set {
    background-color: var(--orange100);
    color: var(--orange700);
}

.blue-set {
    background-color: var(--blue100);
    color: var(--blue700);
}

.red-set {
    background-color: var(--red100);
    color: var(--red-700);
}

.violet-set {
    background-color: var(--violet100);
    color: var(--violet700);
}


.success-set.filled svg {
    stroke: var(--green100);
    fill: var(--green700);
}

.orange-set.filled svg {
    stroke: var(--orange100);
    fill: var(--orange700);
}

.blue-set.filled svg {
    stroke: var(--blue100);
    fill: var(--blue700);
}

.red-set.filled svg {
    stroke: var(--red100);
    fill: var(--red-700);
}

.violet-set.filled svg {
    stroke: var(--violet100);
    fill: var(--violet700);
}
