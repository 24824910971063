.maintainance main {
    height: 100vh;
    display: flex;
    margin: 0 20px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.maintainance main h1 {
    font-size: 3em;
    font-weight: 100;
    color: black;
    margin: 0;
}

.maintainance main h2 {
    font-size: 1.5em;
    font-weight: 100;
    margin-bottom: 0;
}

.maintainance main h3 {
    font-size: 1.5em;
    font-weight: 100;
    margin-top: 0;
}

.maintainance main a {
    font-size: 1.5em;
    font-weight: 300;
    color: black;
    text-decoration: none;
}
