/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 29/08/22
 * Time: 10:02 AM
 * Project: W-CRM
 */
.filter-parent-container {
  width: 100%;
}

.filter-item-holder {
  display: flex;
  gap: 1em;
}

.filter-item {
  flex: 1;
  max-width: 500px;
}

.filter-toggle {
  padding: 10px;
  height: 2.5em;
}

.filter-mobile-menu-icon {
  cursor: pointer;
}

.filter-mob-holder {
  display: flex;
  /*width: 100%;*/
  flex: 1;
  flex-direction: column;
  gap: 1em;
}
