/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 25/08/22
 * Time: 2:41 PM
 * Project: W-CRM
 */
:root {
    --navigation-top-nav-height: 4rem;
    --navigation-bottom-nav-height: 5rem;
    --navigation-left-nav-width: 7rem;
    --navigation-left-nav-collapsed-width: 0rem;
    --navigation-page-width: calc(
            100% - var(--navigation-left-nav-collapsed-width)
    );
    --navigation-body-container-height: calc(
            100% - var(--navigation-top-nav-height)
    );
    --bottom-navigation-active-inticator: var(--secondary-color);
    --vertical-icon-size: 4rem;
}

.navigation-parent-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.navigation-top-nav-container {
    height: var(--navigation-top-nav-height);
    width: 100%;
}

.navigation-body-container {
    /*height: calc(*/
    /*  var(--navigation-body-container-height) -*/
    /*    var(--navigation-bottom-nav-height)*/
    /*);*/
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    background: var(--secondary-color);
    overflow: hidden;
}

.mobile-navigation-header-holder {
    display: flex;
    gap: 6px;
    padding-top: 2px;
}

.navigation-vertical-toggle-holder {
    position: absolute;
    bottom: var(--primary-small-padding);
    left: calc(var(--navigation-left-nav-width) - 25px);
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: white;
    background: var(--primary);
    z-index: 8;
    display: none;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transition: 200ms all ease-in-out;
}

.navigation-left-nav-container {
    width: 100%;
    height: 100%;
    background: var(--primary);
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 0.75rem;
    transition: all 0.2s;
}

.navigation-right-nav-container {
    width: 100%;
    height: 100%;
    background: var(--secondary-color);
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 1;
}

.navigation-page-container {
    flex: 1;
    background: var(--secondary-color);
    display: flex;
    flex-direction: column;
    /*max-width: 1900px;*/
}

.navigation-bottom-nav-container {
    position: relative;
    height: var(--navigation-bottom-nav-height);
    background: var(--secondary-color);
}

.onlyMobile {
    display: block;
}

/*.navigation-left-nav-container.collapsed {*/
/*    position: absolute;*/
/*    z-index: -1;*/
/*}*/

.onlyPC {
    display: none;
}

.refresh-holder {
    display: none;
}

@media only screen and (min-width: 780px) and (max-width: 1280px) {
    .navigation-body-container {
        /*padding-left: calc(var(--vertical-icon-size) + 1rem);*/
        padding-left: 0;
    }
}

@media only screen and (min-width: 1280px) {
    .navigation-left-nav-container.collapsed {
        position: relative;
        z-index: 1;
    }

    .navigation-body-container {
        padding-left: 0;
    }
}

/*@media only screen and (min-width: 2100px) {*/
/*  .navigation-left-nav-container{*/
/*    position: fixed!important;*/
/*    padding-top: var(--navigation-top-nav-height);*/
/*    left: 0;*/
/*  }*/
/*  .navigation-vertical-toggle-holder {*/
/*    position: fixed;*/
/*    left: 25px;*/
/*  }*/
/*}*/
@media only screen and (min-width: 600px) {
    .onlyMobile {
        display: none;
    }

    .onlyPC {
        display: block;
    }

    .refresh-holder {
        display: flex;
        cursor: pointer;
    }
}

.onlyNavMobile {
    display: block;
}

.onlyNavPC {
    display: none;
}

@media only screen and (max-width: 780px) {
    .navigation-left-nav-container {
        z-index: -1;
    }
}

@media only screen and (min-width: 780px) {
    .onlyNavMobile {
        display: none;
    }

    .onlyNavPC {
        display: block;
    }

    .navigation-bottom-nav-container {
        display: none;
    }

    .navigation-page-container {
        width: var(--navigation-page-width);
    }

    .navigation-body-container {
        height: var(--navigation-body-container-height);
    }

    .navigation-left-nav-container {
        position: relative;
        width: var(--navigation-left-nav-width);
        z-index: 8;
    }

    .navigation-vertical-toggle-holder {
        display: flex;
    }
}

/*Top Navigation*/
.top-navigation-parent-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--secondary-color);
}

.top-navigation-brand-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-navigation-brand-container img {
    max-width: 6rem;
    max-height: 2rem;
}

.top-navigation-brand-container .top-navigation-brand-pc {
    display: none;
}

.top-navigation-brand-container .top-navigation-brand-mobile {
    display: block;
}

@media only screen and (min-width: 600px) {
    .top-navigation-brand-container .top-navigation-brand-pc {
        display: block;
    }

    .top-navigation-brand-container .top-navigation-brand-mobile {
        display: none;
    }

    .top-navigation-brand-container {
        height: 100%;
        width: var(--navigation-left-nav-width);
    }
}

.navigation-vertical-item-holder.active {
    background: var(--primary-dark);
    color: white;
}

.navigation-vertical-item-holder:hover {
    background: var(--primary-dark);
}

.navigation-vertical-item-holder {
    background: var(--primary);
    color: #fff;
    width: 100%;
    padding: 10px 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.nav-icon-holder {
    width: 20px;
}

.navigation-vertical-item-data-title {
    width: 90%;
    /*transition: color 0ms;*/
}

.navigation-vertical-item-active-indicator {
    height: 20px;
    width: 3px;
    border-radius: 20px;
    background: white;
}

.navigation-vertical-item-container {
    padding-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: var(--primary-small-padding);
}

.navigation-left-nav-container.collapsed {
    display: flex;
    margin-left: calc(var(--navigation-left-nav-width) * -1);
    justify-content: center;
    padding: 0;
    position: relative;
}

.navigation-left-nav-container.collapsed ~ .navigation-vertical-toggle-holder {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    left: 10px;
}

.navigation-left-nav-container.collapsed:hover::before {
    width: 0;
}

.navigation-left-nav-container.collapsed::before {
    content: " ";
    width: 50px;
    position: absolute;
    height: 100%;
    transition: 100ms width ease-in-out;
    margin-left: calc(var(--navigation-left-nav-width) + 9px);
}

.navigation-left-nav-container:hover {
    margin-left: 0;
}

.navigation-left-nav-container.collapsed:hover ~ .navigation-vertical-toggle-holder {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    left: -100px;
}

.navigation-vertical-toggle-holder {
    /*left: calc(var(--navigation-left-nav-width) - 25px);*/
}

.navigation-right-nav-container.collapsed {
    transform: translateX(100%);
    -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%);
}

.navigation-left-nav-container.collapsed .navigation-vertical-item-data-title {
    font-size: 0;
}

/*Bottom Mobile Navigation*/
.navigation {
    position: relative;
    width: 100%;
    height: 70px;
    background: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1;
}

.navigation-liner {
    height: 8px;
    width: 100%;
    background: var(--secondary-color);
    position: absolute;
    z-index: 1;
    top: -8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.navigation-liner:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom-left-radius: 20px;
    background: var(--secondary-color);
    right: 0;
    bottom: -10px;
}

.navigation-liner:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom-right-radius: 20px;
    background: var(--secondary-color);
    left: 0;
    bottom: -10px;
}

.navigation ul {
    display: flex;
    width: 350px;
}

.navigation ul li {
    position: relative;
    list-style: none;
    width: 70px;
    height: 70px;
    z-index: 1;
}

.navigation ul li a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 500;
}

.navigation ul li a .text {
    position: absolute;
    font-weight: 400;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(20px);
    -ms-transform: translateY(20px);
    -webkit-transform: translateY(20px);
}

.navigation ul li.active a .text {
    opacity: 1;
    transform: translateY(10px);
    -ms-transform: translateY(10px);
    -webkit-transform: translateY(10px);
}

.indicator {
    position: absolute;
    top: -50%;
    width: 70px;
    height: 70px;
    background: var(--bottom-navigation-active-inticator);
    border-radius: 50%;
    border: 6px solid var(--secondary-color);
    transition: 0.5s;
    z-index: 0;
}

.navigation ul li a .icon {
    position: relative;
    display: block;
    line-height: 75px;
    font-size: 1.5em;
    text-align: center;
    transition: 0.5s;
    z-index: 1;
}

.navigation ul li.active a .icon {
    transform: translateY(-38px);
    -ms-transform: translateY(-38px);
    -webkit-transform: translateY(-38px);
}

.indicator:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 12px;
    top: 50%;
    left: -22px;
    border-top-right-radius: 20px;
    box-shadow: 1px -8px 0 0 var(--secondary-color);
    opacity: 1;
    transition: opacity 1ms ease-in-out;
}

.indicator:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 12px;
    top: 50%;
    right: -22px;
    border-top-left-radius: 20px;
    box-shadow: -1px -8px 0 0 var(--secondary-color);
    opacity: 1;
    transition: opacity 1ms ease-in-out;
}

/*.navigation.collapsed .indicator:before {*/
/*    transition: opacity 1s ease-in-out;*/
/*    opacity: 1;*/
/*}*/

/*.navigation.collapsed .indicator:after {*/
/*    transition: opacity 1s ease-in-out;*/
/*    opacity: 1;*/
/*}*/

.navigation ul li:nth-child(1).active ~ .indicator {
    transform: translateX(calc(70px * 0));
    -ms-transform: translateX(calc(70px * 0));
    -webkit-transform: translateX(calc(70px * 0));
}

.navigation ul li:nth-child(2).active ~ .indicator {
    transform: translateX(calc(70px * 1));
    -ms-transform: translateX(calc(70px * 1));
    -webkit-transform: translateX(calc(70px * 1));
}

.navigation ul li:nth-child(3).active ~ .indicator {
    transform: translateX(calc(70px * 2));
    -ms-transform: translateX(calc(70px * 2));
    -webkit-transform: translateX(calc(70px * 2));
}

.navigation ul li:nth-child(4).active ~ .indicator {
    transform: translateX(calc(70px * 3));
    -ms-transform: translateX(calc(70px * 3));
    -webkit-transform: translateX(calc(70px * 3));
}

.top-navigation-right-controls {
    display: flex;
    gap: 2rem;
}

.profile-dropdown {
    position: absolute;
    z-index: 11;
    background: white;
    width: 10rem;
    right: 1rem;
    display: flex;
    padding: 5px;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    border-radius: 10px;
}

.profile-dropdown-item:hover {
    background: var(--gray50);
}

.profile-dropdown-item {
    display: flex;
    height: 2rem;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    background: white;
    width: 100%;
    justify-content: start;
    align-items: center;
}

.navigation-ipad-toggle {
    position: absolute;
    z-index: 100;
    height: 40px;
    width: 40px;
    top: 1rem;
    left: 1rem;
    border-radius: 100%;
    color: white;
    display: none;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    cursor: pointer;
    transition: 0ms;
}

@media only screen and (min-width: 780px) and (max-width: 1200px) {
    .navigation-ipad-toggle {
        display: flex;
    }

    /*.navigation-left-nav-container {*/
    /*    position: absolute;*/
    /*    z-index: 100;*/
    /*}*/
    /*.navigation-left-nav-container.collapsed {*/
    /*    !*z-index: -1;*!*/
    /*}*/
}

.vertical-nav-icon-label-parent-holder {
    flex: 1;
    width: 100%;
}

.vertical-nav-icon-label {
    font-size: var(--small-font);
    text-align: center;
    margin-top: 0.5rem;
}

.mobile-navigation-brand-icon {
    max-width: 6rem;
    max-height: 2rem;
}

.mobile-navigation-holder {
    padding: var(--primary-padding);
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.mobile-navigation-item-holder {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-navigation-item {
    border: solid 1px var(--gray50);
    border-radius: var(--primary-border-radius);
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.mobile-navigation-item.active {
    border: solid 1px var(--gray200);
    font-weight: 500;
}

.mobile-navigation-item .text {
    font-size: var(--heading-font);
}

.mobile-navigation-item .icon {
    font-size: 30px;
}

.mobile-nav-icon {
    width: 20px;
}

.mobile-navigation-toggle-holder {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-name-card-container {
    height: 1cm;
    width: 2cm;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-bg);
    gap: var(--primary-padding);
    padding: 4px;
}
.nav-name-dp-holder{
    height: .7cm;
    width: .7cm;
}
.top-nav-dp-text-holder {
    color: var(--primary-dark);
    font-weight: 600;
    text-transform: capitalize;
}

.top-nav-dp-holder {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.top-nav-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
}