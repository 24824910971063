/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 4/12/2022
 * Time: 5:14 PM
 * Project: w_crm
 */
.action-slot {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: var(--max-width-size);
}

.nav-action-desktop-holder {
    display: flex;
    gap: var(--primary-padding);
    padding-right: var(--primary-padding);
}


.breadcrumb-item-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: var(--primary-small-padding);
}

.breadcrumb-icon {
    width: 15px;
    stroke-width: 2px;
}

.notice-container {
    z-index: 7;
    display: flex;
    justify-content: center;
    gap: 5px;
    max-width: 500px;
    border-radius: var(--primary-padding);
    background: red;
    padding: 4px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.bread-notice-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.breadcrumb-container {
    padding-left: var(--primary-padding);
    display: flex;
    gap: var(--primary-padding);
}

.breadcrumb-container.default {
    padding: var(--primary-padding);
}