.spin-icon {
  animation: spin 1s linear infinite;
  height: auto;
  width: 1rem;
  margin-right: 0.5rem;
  margin-left: -1px;
}
.section-spin {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  z-index: 10;
}
.section-spin-icon {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  animation: spin 1s linear infinite;
  top: 10%;
  left: 50%;
}
.section-spin-children {
  all: inherit;
  user-select: none;
  pointer-events: none;
  opacity: 0.4;
}
.section-spin-children-non-spin {
  all: inherit;
  transition: 100ms all ease-in-out;
}
.section-spin-container {
  all: inherit;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  transform: none;
  -ms-transform: none;
  -webkit-transform: none;
  /*overflow-y: revert;*/
}
