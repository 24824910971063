/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 23/09/22
 * Time: 1:10 PM
 * Project: W-CRM
 */
/*.notesViewerFlipMaxSize {*/
/*  flex: 1;*/
/*  min-width: 12em;*/
/*  min-height: 30em;*/
/*}*/

/*.notesViewerFlip-task-holder {*/
/*  height: 100%;*/
/*}*/

.leadDetail-icon-value-holder {
    display: flex;
    gap: 4px;
}

.leadDetail-client-detail-holder {
    display: flex;
    align-items: center;
    gap: 4px;
    /*height: 2em;*/
}

/*.appointmentDetails-container {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  gap: 1em;*/
/*}*/

.leadDetail-action-holder {
    display: flex;
    gap: 1em;
}

.leadDetail-appointment-cancel-holder {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.leadDetail-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 100%;
}

.leadDetail-icon-value-container {
    display: flex;
    gap: 1em;
}

.leadDetail-action-icon-list {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

/*!* Code Here For Mobile Screen  *!*/

/*!* Below 425px Mobile *!*/
/*!* 425px - 768px Tablet *!*/
/*@media (min-width: 425px) {*/
/*  !* Code Here For Tablet Screen  *!*/
/*}*/

/*!* Above 768px Desktop *!*/
/*@media (min-width: 768px) {*/
/*  !* Code Here For Desktop Screen  *!*/
/*  .notesViewerFlipMaxSize {*/
/*    min-width: 42em;*/
/*    min-height: 30em;*/
/*  }*/

/*  .notesViewerFlipMaxSize.active {*/
/*    min-width: 25em;*/
/*    min-height: 30em;*/
/*  }*/
/*}*/
.list-view-holder {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.list-view-table {
    border-collapse: separate;
    border-spacing: 0 15px;
    margin-top: -25px;
    /*max-width: 1500px;*/
    max-width: 1920px;
}

.list-view-row-item-holder {
    background: var(--primary-bg);
    border-radius: var(--primary-border-radius);
    border: 1px solid var(--gray50);
}

.list-view-row-item-holder.selected {
    background: var(--gray100);
}

.list-view-row-item-holder td {
    padding-top: var(--primary-xtra-padding);
    padding-bottom: var(--primary-xtra-padding);
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.list-view-row-item-holder td:first-child {
    padding-left: 10px;
    border-top-left-radius: var(--primary-border-radius);
    border-bottom-left-radius: var(--primary-border-radius);
    border-left: 1px solid var(--border-color);
}

.list-view-row-item-holder td:last-child {
    padding-right: 10px;
    border-top-right-radius: var(--primary-border-radius);
    border-bottom-right-radius: var(--primary-border-radius);
    border-right: 1px solid var(--border-color);
}

.list-item-w-2 {
    width: 2%;
    max-width: 2%;
}

.list-item-w-5 {
    width: 5%;
    max-width: 5%;
}

.list-item-w-10 {
    width: 10%;
    max-width: 10%;
}

.list-item-w-20 {
    width: 20%;
    max-width: 20%;
}

.list-item-w-30 {
    width: 30%;
    max-width: 30%;
}

.list-item-w-40 {
    width: 40%;
    max-width: 40%;
}

.list-item-w-50 {
    width: 50%;
    max-width: 50%;
}

.list-item-w-60 {
    width: 60%;
    max-width: 60%;
}

.list-item-w-70 {
    width: 70%;
    max-width: 70%;
}

.list-item-w-80 {
    width: 80%;
    max-width: 80%;
}

.list-item-w-90 {
    width: 90%;
    max-width: 90%;
}

.list-item-w-100 {
    width: 100%;
    max-width: 100%;
}

.lead-appointment-holder {
    width: 100%;
}

.list-view-row-raw-item-holder {
    display: flex;
    height: 100%;
    width: 98%;
    flex-direction: row;
    gap: var(--primary-padding);
    align-items: center!important;
}

.list-view-row-raw-item-holder .key {
    font-size: var(--small-font);
    font-weight: var(--medium-font-weight);
}

.list_heading {
    font-size: var(--small-font);
    font-weight: var(--medium-font-weight);
}

.list-badge-container {
    background: var(--layout-bg);
    color: var(--primary-light);
    margin-left: 10px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: var(--ultra-small-font);
    text-align: center;
}

.list-view-row-raw-item-holder .value {
    font-size: var(--default-font);
}

.max-lines {
    min-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.small-class {
    min-width: 10%;
}

.medium-class {
    min-width: 25%;
    max-width: 25%;
}

.large-class {
    min-width: 40%;
    max-width: 40%;
}

.list-view-holder.card-mode .list-view-row-raw-item-holder {
    display: flex;
    max-width: 100%;
}

.list-view-holder.card-mode .list-view-row-item-holder td {
    border-top: none;
    border-bottom: none;
}

.list-view-holder.card-mode > .list-view-table {
    gap: 1em;
    margin-bottom: 0;
    background-color: transparent;
}

.list-view-holder.card-mode .list-view-row-raw-item-holder {
    display: flex;
}

.list-view-holder.card-mode > .list-view-table > thead {
    display: none;
}

.list-view-holder.card-mode .list-view-row-raw-item-holder {
    display: flex;
    padding-right: var(--primary-padding);
    flex-direction: row;
    max-width: 100%;
}

.list-view-holder.card-mode .max-lines {
    min-width: 50%;
}

.list-view-holder.card-mode .list-view-row-raw-item-holder .value {
    text-align: right;
    width: 50%;
}

.list-view-holder.card-mode .list-view-row-raw-item-holder .key {
    text-align: left;
    width: 50%;
}

.list-view-holder.card-mode td {
    display: flex;
    padding-right: var(--primary-padding);
    padding-left: var(--primary-padding);
}

.list-view-holder.card-mode td {
    padding-top: var(--primary-xtra-padding);
    padding-bottom: var(--primary-xtra-padding);
    border-right: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
}

.list-view-holder.card-mode td:first-child {
    padding-left: 10px;
    border-top-left-radius: var(--primary-border-radius);
    border-top-right-radius: var(--primary-border-radius);
    border-bottom-left-radius: 0;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
}

.list-view-holder.card-mode .list-view-table {
    margin-top: 0;
}

.list-view-holder.card-mode td:last-child {
    padding-right: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: var(--primary-border-radius);
    border-bottom-left-radius: var(--primary-border-radius);
    border-right: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.list-col-container {
    display: flex;
    gap: var(--primary-medium-padding);
    /*justify-content: space-between;*/
    width: 100%;
    flex-direction: row;
}

.list-col-container.mobile {
    flex-direction: row;
}

.list-col-container.pc {
    flex-direction: column;
}

/*let mobileWidth = 420;*/
/*let tabletWidth = 780;*/
/*let desktopWidth = 1024;*/
/*@media (min-width: 939px) {*/
/*  .list-col-container {*/
/*    flex-direction: column;*/
/*  }*/
/*}*/

/*@media (min-width: 700px) {*/
/*  .list-col-container {*/
/*    !*flex-direction: column;*!*/
/*  }*/
/*}*/
/*@media (max-width: 768px) {*/
/*  .list-col-container {*/
/*    !*flex-direction: row;*!*/
/*  }*/

/*  !*.list-col-container .value {*!*/
/*  !*  text-align: right;*!*/
/*  !*  width: 50%;*!*/
/*  !*}*!*/

/*  !*.list-col-container .key {*!*/
/*  !*  text-align: left;*!*/
/*  !*  width: 50%;*!*/
/*  !*}*!*/
/*}*/
