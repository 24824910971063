/**
* Created by WorkAxis www.workaxis.in
* Developer: Padma Dev.E
* Developer Contact: padmadev.e@workaxis.in
* Admin: shyam@workaxis.in
* Date: 07/11/22
* Time: 10:21 AM
* Project: W-CRM
*/
:root {
    --filter-height: 100px;
}

.playgroundParentContainer {
    border-radius: var(--primary-border-radius);
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    flex: 0;
    width: 100%;
    /*background: yellow;*/
}

.playgroundHeader {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.playgroundHeadFootHolder {
    flex: 0;
}

.playgroundBody {
    flex: 1;
    /*background: red;*/
    max-height: 100%;
}

.playgroundBody.scrollable {
    overflow: auto;
}

.playgroundFooter {
    display: flex;
    justify-content: end;
}

.playgroundControlHolder {
    display: flex;
    gap: 1em;
    justify-content: end;
}

.playgroundQueryHolder {
    display: flex;
    gap: 1em;
}
