/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev@workaxis.in
 * Date: 21/11/23
 * Time: 8:44 am
 * Project: Waxis
 */
.accountSwitcher-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 100%;
    padding: 5px;
    border-radius: var(--primary-border-radius);
}

.accountSwitcher-item-container.selected {
    padding: 0;
}
.accountSwitcher-item-container.selectable:hover {
    background: var(--gray50);
}

.accountSwitcher-item-icon-holder {
    width: 35px;
    height: 35px;
    border-radius: var(--primary-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
}

.accountSwitcher-selected-container {
    padding: 6px !important;
}

.accountSwitcher-container {
    position: relative;
    width: 170px;
}

.accountSwitcher-list-container {
    position: absolute;
    top: 55px;
    z-index: 10;
    width: 100%;
}

.accountSwitcher-list-holder {
    padding: 3px !important;
    width: 100%;
}

.accountSwitcher-details-holder {
    width: calc(100% - 45px);
}

.accountSwitcher-details-holder.selected {
    width: calc(100% - 65px);
}

.accountSwitcher-list-item-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*gap: var(--primary-padding);*/
}