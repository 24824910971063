.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.p-05 {
    padding: 0.5em;
}

.pt-02 {
    padding-top: 0.2em;
}

.pt-05 {
    padding-top: 0.5em;
}

.pb-05 {
    padding-bottom: 0.5em;
}

.pl-05 {
    padding-left: 0.5em;
}

.pr-05 {
    padding-right: 0.5em;
}

.m-05 {
    margin: 0.5em;
}

.mt-05 {
    margin-top: 0.5em;
}

.mb-05 {
    margin-bottom: 0.5em;
}

.ml-05 {
    margin-left: 0.5em;
}

.ml-02 {
    margin-left: 0.2em;
}

.mt-01 {
    margin-left: 0.1em;
}

.mr-05 {
    margin-right: 0.5em;
}

.p-1 {
    padding: 1em;
}

.pt-1 {
    padding-top: 1em;
}

.pr-1 {
    padding-right: 1em;
}

.pb-1 {
    padding-bottom: 1em;
}

.pl-1 {
    padding-left: 1em;
}

.m-1 {
    margin: 1em;
}

.mt-1 {
    margin-top: 1em;
}

.mt-02 {
    margin-top: 0.2em;
}

.mb-1 {
    margin-bottom: 1em;
}

.ml-1 {
    margin-left: 1em;
}

.p-2 {
    padding: 2em;
}

.pt-2 {
    padding-top: 2em;
}

.pr-2 {
    padding-right: 2em;
}

.pb-020 {
    padding-bottom: 0.2em;
}

.pr-020 {
    padding-right: 0.2em;
}

.pl-020 {
    padding-left: 0.2em;
}

.pb-2 {
    padding-bottom: 2em;
}

.pl-1-5 {
    padding-left: 1.5em;
}

.pr-1-5 {
    padding-right: 1.5em;
}

.ph-1-5 {
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.pl-2 {
    padding-left: 2em;
}

.m-2 {
    margin: 2em;
}

.mt-2 {
    margin-top: 2em;
}

.mb-2 {
    margin-bottom: 2em;
}

.ml-2 {
    margin-left: 2em;
}

.p-3 {
    padding: 3em;
}

.pt-3 {
    padding-top: 3em;
}

.pr-3 {
    padding-right: 3em;
}

.pb-3 {
    padding-bottom: 3em;
}

.pl-3 {
    padding-left: 3em;
}

.m-3 {
    margin: 3em;
}

.mt-3 {
    margin-top: 3em;
}

.mt-3 {
    margin-right: 3em;
}

.mb-3 {
    margin-bottom: 3em;
}

.ml-3 {
    margin-left: 3em;
}

.p-4 {
    padding: 4em;
}

.pt-4 {
    padding-top: 4em;
}

.pr-4 {
    padding-right: 4em;
}

.pb-4 {
    padding-bottom: 4em;
}

.pl-4 {
    padding-left: 4em;
}

.m-4 {
    margin: 4em;
}

.mt-4 {
    margin-top: 4em;
}

.mt-4 {
    margin-right: 4em;
}

.mb-4 {
    margin-bottom: 4em;
}

.ml-4 {
    margin-left: 4em;
}

.p-5 {
    padding: 5em;
}

.pt-5 {
    padding-top: 5em;
}

.pr-5 {
    padding-right: 5em;
}

.pb-5 {
    padding-bottom: 5em;
}

.pl-5 {
    padding-left: 5em;
}

.m-5 {
    margin: 5em;
}

.mt-5 {
    margin-top: 5em;
}

.mt-5 {
    margin-right: 5em;
}

.mb-5 {
    margin-bottom: 5em;
}

.ml-5 {
    margin-left: 5em;
}

.p-6 {
    padding: 6em;
}

.pt-6 {
    padding-top: 6em;
}

.pr-6 {
    padding-right: 6em;
}

.pb-6 {
    padding-bottom: 6em;
}

.pl-6 {
    padding-left: 6em;
}

.m-6 {
    margin: 6em;
}

.mt-6 {
    margin-top: 6em;
}

.mt-6 {
    margin-right: 6em;
}

.mt-1-5 {
    margin-top: 1.5rem;
}

.mt-2-5 {
    margin-top: 2.5rem;
}

.mb-6 {
    margin-bottom: 6em;
}

.ml-6 {
    margin-left: 6em;
}

.p-7 {
    padding: 7em;
}

.pt-7 {
    padding-top: 7em;
}

.pr-7 {
    padding-right: 7em;
}

.pb-7 {
    padding-bottom: 7em;
}

.pl-7 {
    padding-left: 7em;
}

.m-7 {
    margin: 7em;
}

.mt-7 {
    margin-top: 7em;
}

.mt-7 {
    margin-right: 7em;
}

.mb-7 {
    margin-bottom: 7em;
}

.ml-7 {
    margin-left: 7em;
}

.p-8 {
    padding: 8em;
}

.pt-8 {
    padding-top: 8em;
}

.pr-8 {
    padding-right: 8em;
}

.pb-8 {
    padding-bottom: 8em;
}

.pl-8 {
    padding-left: 8em;
}

.m-8 {
    margin: 8em;
}

.mt-8 {
    margin-top: 8em;
}

.mt-8 {
    margin-right: 8em;
}

.mb-8 {
    margin-bottom: 8em;
}

.ml-8 {
    margin-left: 8em;
}

.p-9 {
    padding: 9em;
}

.pt-9 {
    padding-top: 9em;
}

.pr-9 {
    padding-right: 9em;
}

.pb-9 {
    padding-bottom: 9em;
}

.pl-9 {
    padding-left: 9em;
}

.m-9 {
    margin: 9em;
}

.mt-9 {
    margin-top: 9em;
}

.mt-9 {
    margin-right: 9em;
}

.mb-9 {
    margin-bottom: 9em;
}

.ml-9 {
    margin-left: 9em;
}

.p-10 {
    padding: 10em;
}

.pt-10 {
    padding-top: 10em;
}

.pr-10 {
    padding-right: 10em;
}

.pb-10 {
    padding-bottom: 10em;
}

.pl-10 {
    padding-left: 10em;
}

.m-10 {
    margin: 10em;
}

.mt-10 {
    margin-top: 10em;
}

.mt-10 {
    margin-right: 10em;
}

.mb-10 {
    margin-bottom: 10em;
}

.ml-10 {
    margin-left: 10em;
}

.p-11 {
    padding: 11em;
}

.pt-11 {
    padding-top: 11em;
}

.pr-11 {
    padding-right: 11em;
}

.pb-11 {
    padding-bottom: 11em;
}

.pl-11 {
    padding-left: 11em;
}

.m-11 {
    margin: 11em;
}

.mt-11 {
    margin-top: 11em;
}

.mt-11 {
    margin-right: 11em;
}

.mb-11 {
    margin-bottom: 11em;
}

.ml-11 {
    margin-left: 11em;
}

.p-12 {
    padding: 12em;
}

.pt-12 {
    padding-top: 12em;
}

.pr-12 {
    padding-right: 12em;
}

.pb-12 {
    padding-bottom: 12em;
}

.pl-12 {
    padding-left: 12em;
}

.m-12 {
    margin: 12em;
}

.mt-12 {
    margin-top: 12em;
}

.mt-12 {
    margin-right: 12em;
}

.mb-12 {
    margin-bottom: 12em;
}

.ml-12 {
    margin-left: 12em;
}

.p-13 {
    padding: 13em;
}

.pt-13 {
    padding-top: 13em;
}

.pr-13 {
    padding-right: 13em;
}

.pb-13 {
    padding-bottom: 13em;
}

.pl-13 {
    padding-left: 13em;
}

.m-13 {
    margin: 13em;
}

.mt-13 {
    margin-top: 13em;
}

.mt-13 {
    margin-right: 13em;
}

.mb-13 {
    margin-bottom: 13em;
}

.ml-13 {
    margin-left: 13em;
}

.p-14 {
    padding: 14em;
}

.pt-14 {
    padding-top: 14em;
}

.pr-14 {
    padding-right: 14em;
}

.pb-14 {
    padding-bottom: 14em;
}

.pl-14 {
    padding-left: 14em;
}

.m-14 {
    margin: 14em;
}

.mt-14 {
    margin-top: 14em;
}

.mt-14 {
    margin-right: 14em;
}

.mb-14 {
    margin-bottom: 14em;
}

.ml-14 {
    margin-left: 14em;
}

.p-15 {
    padding: 15em;
}

.pt-15 {
    padding-top: 15em;
}

.pr-15 {
    padding-right: 15em;
}

.pb-15 {
    padding-bottom: 15em;
}

.pl-15 {
    padding-left: 15em;
}

.m-15 {
    margin: 15em;
}

.mt-15 {
    margin-top: 15em;
}

.mt-15 {
    margin-right: 15em;
}

.mb-15 {
    margin-bottom: 15em;
}

.ml-15 {
    margin-left: 15em;
}

.pv-05 {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.pv-1 {
    padding-top: 1em;
    padding-bottom: 1em;
}

.ph-05 {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.ph-1 {
    padding-left: 1em;
    padding-right: 1em;
}

.pv-2 {
    padding-top: 2em;
    padding-bottom: 2em;
}

.ph-2 {
    padding-left: 2em;
    padding-right: 2em;
}

.pv-3 {
    padding-top: 3em;
    padding-bottom: 3em;
}

.ph-3 {
    padding-left: 2em;
}

.pv-4 {
    padding-top: 4em;
    padding-bottom: 4em;
}

.ph-4 {
    padding-left: 4em;
    padding-right: 4em;
}

.pv-5 {
    padding-top: 5em;
    padding-bottom: 5em;
}

.ph-5 {
    padding-left: 5em;
    padding-right: 5em;
}

.pv-6 {
    padding-top: 6em;
    padding-bottom: 6em;
}

.ph-6 {
    padding-left: 6em;
    padding-right: 6em;
}

.pv-7 {
    padding-top: 7em;
    padding-bottom: 7em;
}

.ph-7 {
    padding-left: 7em;
    padding-right: 7em;
}

.pv-8 {
    padding-top: 8em;
    padding-bottom: 8em;
}

.ph-8 {
    padding-left: 8em;
    padding-right: 8em;
}

.pv-9 {
    padding-top: 9em;
    padding-bottom: 9em;
}

.ph-9 {
    padding-left: 9em;
    padding-right: 9em;
}

.pv-10 {
    padding-top: 10em;
    padding-bottom: 10em;
}

.ph-10 {
    padding-left: 10em;
    padding-right: 10em;
}

.pv-11 {
    padding-top: 11em;
    padding-bottom: 11em;
}

.ph-11 {
    padding-left: 11em;
    padding-right: 11em;
}

.pv-12 {
    padding-top: 12em;
    padding-bottom: 12em;
}

.ph-12 {
    padding-left: 12em;
    padding-right: 12em;
}

.pv-13 {
    padding-top: 13em;
    padding-bottom: 13em;
}

.ph-13 {
    padding-left: 13em;
    padding-right: 13em;
}

.pv-14 {
    padding-top: 14em;
    padding-bottom: 14em;
}

.ph-14 {
    padding-left: 14em;
    padding-right: 14em;
}

.pv-15 {
    padding-top: 15em;
    padding-bottom: 15em;
}

.ph-15 {
    padding-left: 15em;
    padding-right: 15em;
}

.h-1 {
    height: 1em;
}

.w-0-8 {
    width: 0.8em;
}

.w-1 {
    width: 1em;
}

.h-2 {
    height: 2em;
}

.w-2 {
    width: 2em;
}

.w-1-5 {
    width: 1.5rem;
}

.w-1-25 {
    width: 1.2em;
}

.h-1-5 {
    height: 1.5rem;
}

.h-3 {
    height: 3em;
}

.w-3 {
    width: 3em;
}

.h-4 {
    height: 4em;
}

.w-4 {
    width: 4em;
}

.h-5 {
    height: 5em;
}

.w-5 {
    width: 5em;
}

.h-6 {
    height: 6em;
}

.w-6 {
    width: 6em;
}

.h-7 {
    height: 7em;
}

.w-7 {
    width: 7em;
}

.h-8 {
    height: 8em;
}

.w-8 {
    width: 8em;
}

.h-9 {
    height: 9em;
}

.w-9 {
    width: 9em;
}

.h-10 {
    height: 10em;
}

.w-10 {
    width: 10em;
}

.h-11 {
    height: 11em;
}

.w-11 {
    width: 11em;
}

.h-12 {
    height: 12em;
}

.w-12 {
    width: 12em;
}

.h-13 {
    height: 13em;
}

.w-13 {
    width: 13em;
}

.h-14 {
    height: 14em;
}

.w-14 {
    width: 14em;
}

.h-15 {
    height: 15em;
}

.w-15 {
    width: 15em;
}

.px-2-5 {
    padding-left: 0.625rem; /* 10px */
    padding-right: 0.625rem; /* 10px */
}

.py-1-5 {
    padding-top: 0.375rem; /* 6px */
    padding-bottom: 0.375rem; /* 6px */
}

.px-3 {
    padding-left: 0.75rem; /* 12px */
    padding-right: 0.75rem; /* 12px */
}

.py-2 {
    padding-top: 0.5rem; /* 8px */
    padding-bottom: 0.5rem; /* 8px */
}

.px-3-5 {
    padding-left: 0.875rem; /* 14px */
    padding-right: 0.875rem; /* 14px */
}

.py-2-5 {
    padding-top: 0.625rem; /* 10px */
    padding-bottom: 0.625rem; /* 10px */
}

.px-6 {
    padding-left: 1.5rem; /* 24px */
    padding-right: 1.5rem; /* 24px */
}

.py-6 {
    padding-top: 1.5rem; /* 48px */
    padding-bottom: 1.5rem; /* 48px */
}

.pl-7 {
    padding-left: 1.75rem; /* 28px */
}

.pr-7 {
    padding-right: 1.75rem; /* 28px */
}

.px-7 {
    padding-left: 1.75rem; /* 28px */
    padding-right: 1.75rem; /* 28px */
}

/*Media Queries*/

/*.container {*/
/*    padding-right: 15px;*/
/*    padding-left: 15px;*/
/*    margin-right: auto;*/
/*    margin-left: auto*/
/*}*/

/*@media (min-width: 768px) {*/
/*    .container {*/
/*        width: 750px*/
/*    }*/
/*}*/

/*@media (min-width: 992px) {*/
/*    .container {*/
/*        width: 970px*/
/*    }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*    .container {*/
/*        width: 1170px*/
/*    }*/
/*}*/

/*.row {*/
/*    margin-right: -15px;*/
/*    margin-left: -15px*/
/*}*/

/*.row-no-gutters {*/
/*    margin-right: 0;*/
/*    margin-left: 0*/
/*}*/

/*.row-no-gutters [class*=col-] {*/
/*    padding-right: 0;*/
/*    padding-left: 0*/
/*}*/

/*.container-fluid {*/
/*    padding-right: 15px;*/
/*    padding-left: 15px;*/
/*    margin-right: auto;*/
/*    margin-left: auto*/
/*}*/

.reverse-flex {
    flex-direction: column-reverse;
}

.sm-pl-1-5 {
    padding-left: 1.5em;
}

.sm-pr-1-5 {
    padding-right: 1.5em;
}

.sm-pt-1 {
    padding-top: 1em !important;
}

.sm-pb-1 {
    padding-bottom: 1em !important;
}

.md-p-1 {
    padding: 0;
}

.lg-p-1 {
    padding: 0;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .reverse-flex {
        flex-direction: column-reverse;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .sm-pl-1-5 {
        padding-left: 0;
    }

    .sm-pr-1-5 {
        padding-right: 0;
    }

    .reverse-flex {
        flex-direction: row;
    }

    .lg-pr-1 {
        padding-right: 0;
    }

    .md-p-1 {
        padding: 1em;
    }

    .lg-p-1 {
        padding: 0;
    }

    .sm-pt-1 {
        padding-top: 0;
    }

    .sm-pb-1 {
        padding-bottom: 0;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .lg-pr-1 {
        padding-right: 1em;
    }

    .md-p-1 {
        padding: 0;
    }

    .lg-p-1 {
        padding: 1em;
    }
}
