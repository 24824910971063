/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 20/09/22
 * Time: 2:35 PM
 * Project: W-CRM
 */
.info-label-header {
  display: inline-flex;
  gap: 6px;
  justify-content: center;
  font-weight: 500;
}

.info-label-icon {
  cursor: pointer;
}

.info-label-text {
  font-size: var(--default-font);
  /*font-weight: 500;*/
}

.custom-fields-parent-container {
  padding: 1rem;
  /*background: white;*/
  border-radius: 12px;
}

.info-label-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;
}

.info-label-body {
  width: 100%;
  text-align: start;
}
