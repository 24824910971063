/**

* Created by WorkAxis www.workaxis.in
* Developer: Padma Dev.E
* Developer Contact: padmadev.e@workaxis.in
* Admin: shyam@workaxis.in
* Date: 08/11/22
* Time: 4:53 PM
* Project: W-CRM
*/

.card-parent-holder {
  background: var(--primary-bg);
  border-radius: var(--primary-border-radius);
  border: 1px solid var(--gray50);
  padding: var(--primary-padding);
  position: relative;
}
.card-header-holder {
  display: flex;
  flex-direction: row;
  gap: var(--primary-padding);
}
.card-header-title {
  font-size: var(--heading-font);
  font-weight: var(--default-font-weight);
  display: flex;
}

.card-footer-holder {
  font-size: var(--default-font);
}

.card-holder-body {
  display: flex;
}

.no-card-wrap {
  height: 100%;
  width: 100%;
}
.card-action-holder {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
