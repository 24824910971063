/**
* Created by WorkAxis www.workaxis.in
* Developer: Padma Dev.E
* Developer Contact: padmadev.e@workaxis.in
* Admin: shyam@workaxis.in
* Date: 08/11/22
* Time: 10:28 AM
* Project: W-CRM
*/

.select-list-holder {
    position: absolute;
    padding: 0.5rem 0px;
    overflow-y: auto;
    background: var(--white);
    max-height: 20em;
    width: fit-content;
    min-width: 100%;
    border-radius: var(--primary-border-radius);
    z-index: 25;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.select-search-holder {
    padding: 0.5rem;
}

.no-gap-list-item {
    /*background: red;*/
}

.select-list-holder li:not(.select-search-holder) {
    cursor: pointer;
    align-items: center;
    padding: 0.5rem 2rem;
}

.select-list-holder li.selected {
    font-weight: 550;
}

.select-list-holder li.selected::before {
    content: ".";
    margin-right: 5px;
    background: var(--primary);
    margin-left: -10px;
    color: var(--primary);
}

.select-list-holder li:hover:not(.select-search-holder) {
    background: var(--primary);
    color: #fff;
}

.select-list-holder li.disabled {
    color: var(--gray700);
}

.select-list-holder li.selected.disabled::before {
    content: ".";
    background: var(--gray700);
    color: var(--gray700);
}

.select-list-holder li.disabled:hover:not(.select-search-holder) {
    background: var(--gray50);
    color: var(--gray700);
}

.selectList-spinner-override {
    /*overflow: hidden;*/
    overflow: unset;
    /*background: red !important;*/
    border: none;
    min-height: 30px;
    box-shadow: none;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
}

.select-list-holder.top {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
    bottom: 40px;
    right: 0;
}

.select-list-item-holder {
}