/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;700&display=swap');*/

/*@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");*/

:root {
    --blue100: #caecff;
    --blue700: #01a4ff;
    --orange100: #ffeaca;
    --orange700: #ff9900;
    --violet100: #e8d9ff;
    --violet700: #6500ff;
    --green100: #deffd9;
    --green700: #06cb02;
    --green900: #25a121;
    --gray50: rgb(243, 241, 239);
    --gray100: #f3f3f3;
    --gray150: #e8e8e8;
    --gray200: #dadada;
    --gray700: #999;
    --whiteSmoke50: #f4f5fa;
    --whiteSmoke100: #f8f9fd;
    --red100: #ffd9d9;
    --white: #ffffff;
    --yellow-50: rgb(254 252 232);
    --yellow-800: rgb(133 77 14);
    --yellow-700: rgb(161 98 7);
    --yellow-400: rgb(250 204 21);
    --blue-50: rgb(239 246 255);
    --blue-400: rgb(96 165 250);
    --blue-800: rgb(30 64 175);
    --blue-700: rgb(29 78 216);
    --red-50: rgb(254 242 242);
    --red-400: rgb(248 113 113);
    --red-800: rgb(153 27 27);
    --red-700: rgb(185 28 28);
    --green-50: rgb(240 253 244);
    --green-400: rgb(74 222 128);
    --green-800: rgb(22 101 52);
    --green-700: rgb(21 128 61);

    /*--primary: #22232f;*/
    /*--primary-dark: #121218;*/
    --layout-bg: #f6f7fa;
    --primary-light: #43455d;
    --primary-selected: #5584ff;
    --secondary-color: #ffffff;
    --border-color: #e5e7eb;
    --secondary: #ffffff;
    --secondary-light: rgba(209, 213, 219, 0.27);
    --secondary-dark: rgba(191, 191, 191, 0.59);

    --lightBlur: rgba(231, 231, 231, 0.5);
    --borderDefault: rgba(174, 174, 178, 0.5);
    --shadowDefault: rgba(0, 0, 0, 0.05);

    /*--heading-font: 1rem;*/
    /*--default-font: 0.875rem;*/
    /*--small-font: 0.75rem;*/
    /*--ultra-small-font: 0.65rem;*/

    --heading-font: 1.25rem;
    --default-font: 1rem;
    --small-font: 0.875rem;
    --ultra-small-font: 0.75rem;

    --default-font-weight: 600;
    --medium-font-weight: 550;
    --subheading-font: 0.9rem;

    --primary-bg: #fff;
    --primary-border-radius: 8px;
    --primary-extra-border-radius: 20px;
    --primary-small-padding: 6px;
    --primary-medium-padding: 8px;
    --primary-padding: 12px;
    --primary-xtra-padding: 16px;

    --toastify-toast-min-height: 20px !important;

    --a4-paper-width: 180mm;

    --gap-mini: 3px;
    --max-width-size: 1900px;
}

.Toastify__toast {
    border-radius: 50px !important;
}

.Toastify__progress-bar {
    opacity: 0 !important;
    /*display: none !important;*/
    /*position: absolute;*/
    /*bottom: 6px !important;*/
    /*left: 45px !important;*/
    /*border-radius: 25px !important;*/
    /*width: 70% !important;*/
}

.Toastify__close-button {
    display: none !important;
    /*position: absolute !important;*/
    /*top: 57% !important;*/
    /*transform: translateY(-57%) !important;*/
    /*right: 15px !important;*/
}

html {
    font-size: 16px;
}

p {
    font-size: var(--default-font);
    line-height: 1.4rem;
}

body {
    /*display: flex;*/
    /*justify-content: center;*/
    color: #111111;
}

body div {
    /*max-width: 1900px;*/
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /*font-family: Roboto, serif;*/
    /*font-family: "Inter", sans-serif;*/
    font-family: "Inter", sans-serif;


    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.text-18 {
    font-size: 15px;
}

.text-100 {
    font-size: 100px;
}

.text-13 {
    font-size: 13px;
}

.text-12 {
    font-size: 12px !important;
}

input[type="checkbox"] {
    appearance: checkbox !important;
}

input[type="radio"] {
    appearance: radio !important;
}

.text-11 {
    font-size: 11px;
}

.full-height {
    height: 100vh;
}

.inline-block {
    display: inline-block;
}

.full-parent-width {
    width: 100%;
}

.full-parent {
    width: 100%;
    height: 100%;
    /*background: red;*/
}

.background-default {
    background-color: var(--layout-bg);
}

.defaultContentHeight {
    height: 100%;
    position: relative;
    display: flex;
    background-color: red;
}

.noTextOverFlow {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.shadow-wx-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-wx {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-wx-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-sm {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.shadow {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.half-parent-width {
    width: 48%;
}

.default-label {
    font-style: normal;
    font-weight: 500;
    font-size: var(--default-font);
    color: #aeaeb2;
}

.modal-root {
    z-index: 10;
    background: red !important;
}

.active-blue {
    color: var(--blue700)
}


.default-small-container {
    width: 25%;
}

.full-parent-height {
    height: 100% !important;
}

.full-width {
    width: 100vw;
}

.main-page-screen-wider {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /*max-width: 1900px;*/
}

.full-screen {
    height: 100%;
    width: 100%;
    max-height: 2160px;
    max-width: 3840px;
}

.text-heading {
    font-size: var(--heading-font) !important;
    font-weight: 550 !important;
}

.text-default {
    font-size: var(--default-font);
}

.text-small {
    font-size: var(--small-font);
}

.strike-text {
    text-decoration-line: line-through;
}

.text--ultraSmall {
    font-size: var(--ultra-small-font);
}

.text-bold {
    font-weight: 450;
}

.text-bolder {
    font-weight: var(--default-font-weight);
}

.text-sub-heading {
    font-size: var(--subheading-font);
}

.link {
    color: #3f7eec;
}

.sub-screen-default-card {
    min-height: 100px;
    width: 100%;
    background: white;
    border-radius: 1em;
}

.vh-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.v-center {
    display: flex !important;
    justify-content: center !important;
}

.item-center {
    align-items: center;
}

.radius {
    border-radius: var(--primary-border-radius);
}

.inline-flex {
    display: inline-flex;
}

.cursor-clickable {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.opacity-05 {
    opacity: 0.5;
}

.opacity-1 {
    opacity: 1;
}

.no-border {
    border: none;
}

.primary-text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.heading-text {
    font-style: normal;
    font-weight: bold;
    font-size: var(--heading-font);
    line-height: 25px;
}

.black-outline {
    border: 1px solid var(--primary);
}

.direction-column {
    flex-direction: column;
}

.direction-row {
    flex-direction: row;
}

ul {
    list-style-type: none;
}

.br-18 {
    border-radius: 18px;
}

.br-100 {
    border-radius: 100%;
}

.br-5 {
    border-radius: 5px;
}

.br-8 {
    border-radius: 8px;
}

.br-2 {
    border-radius: 2px;
}

.align-items-center {
    align-items: center;
}

.text-center {
    text-align: center;
}

.text-start {
    text-align: start;
}

a {
    color: var(--primary);
    font-size: 14px;
    text-decoration: none;
}

.d-flex {
    display: flex;
}

.no-text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-direction-c {
    flex-direction: column;
}

.flex-direction-r {
    flex-direction: row;
}

.justify-content-center {
    justify-content: center;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-start {
    justify-content: start;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-around {
    justify-content: space-around;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: sticky;
}

.t-0 {
    top: 0;
}

.b-0 {
    bottom: 0;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.l-0 {
    left: 0;
}

.r-0 {
    right: 0;
}

.border-transparent {
    border-color: transparent;
}

.border {
    border-width: 1px;
}

.border-0 {
    border: none;
}

.secondary-text {
    color: var(--gray700);
    /*font-size: 14px;*/
}

.subControl {
    display: flex;
    justify-content: end;
    gap: 1em;
}

.medium-text {
    /*font-weight: 900;*/
    font-size: 19px;
}

.border-gray {
    border: solid rgb(209 213 219 / 1);
}

.divider {
    height: 0.8px;
    background: var(--gray200);
    opacity: 0.5;
    width: 100%;
}

.success-text {
    color: #88b04b;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}

.scrollable {
    overflow-y: revert;
}

/*.scrollable::-webkit-scrollbar {*/
/*  display: revert;*/
/*}*/

.content-Max-height {
    height: calc(100% - var(--top-nav-height));
}

.hide-overflowing-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fade-in-out-animation {
    -webkit-animation: fadeInFromNone 0.5s ease-out;
    -moz-animation: fadeInFromNone 0.5s ease-out;
    -o-animation: fadeInFromNone 0.5s ease-out;
    animation: fadeInFromNone 0.5s ease-out;
}

.no-scrollbar::-webkit-scrollbar {
    width: 0 !important;
}

.no-scroll {
    overflow: hidden;
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.default-heading {
    text-align: center;
    width: 7cm;
}

.invisible-container {
    visibility: hidden;
}

@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinInner {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100% {
        transform: rotate(-720deg);
        -ms-transform: rotate(-720deg);
        -webkit-transform: rotate(-720deg);
    }
}

.error-holder {
    background-color: var(--red100);
    padding: 0.6em;
    color: var(--red-700);
    border: solid 1px var(--red-700);
    border-radius: var(--primary-border-radius);
}

.error-stoke {
    stroke: var(--red-800);
}

.error-text {
    stroke: var(--red-800);
    color: var(--red-800);
}

.success-stoke {
    stroke: var(--green-800);
}

.z-index-3 {
    z-index: 3;
}

::-webkit-scrollbar {
    width: 14px;
}

::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
}

::-webkit-scrollbar-thumb:hover {
    border: 3px solid rgba(0, 0, 0, 0);
}

.ultra-mini-scroll::-webkit-scrollbar {
    width: 2px;
}

.mini-scroll::-webkit-scrollbar {
    width: 4px;
}

.custom-scroll::-webkit-scrollbar {
    background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-track {
    background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #555;
}

.rotate {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}

.center-child {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

* {
    box-sizing: border-box;
    /*transition: 600ms;*/
}

@font-face {
    font-family: "ICONS ROUNDED";
    src: url(https://ik.imagekit.io/kittydev/Workaxis/font/material_icon_uf6DOy7Cx.otf) format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.wx-icons {
    position: relative;
    font-family: "ICONS ROUNDED", sans-serif;
    font-weight: 400;
    text-transform: none;
}

.br-12 {
    border-radius: 12px;
}

.non-clickable {
    cursor: auto !important;
}

.clickable {
    cursor: pointer !important;
}

.moveCursor {
    cursor: move !important;
}

.public-layout-parent-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    background: white;
}

.public-layout-parent-holder {
    width: 100%;
    max-width: var(--max-width-size);
    position: relative;
}

.public-section {
    display: flex;
    justify-content: center;
    background-color: var(--layout-bg);
    align-items: center;
    flex-direction: column;
    min-height: 100%;
}

.new-task-item-holder {
    display: flex;
    flex-direction: column;
    gap: var(--primary-padding);
    min-height: 300px;
    min-width: 350px;
}

.noTransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.alert-error-scheme {
    color: var(--red-400);
    background: var(--red-50);
    border: 1.5px solid var(--red-400);
}

.alert-info-scheme {
    color: var(--blue-400);
    background: var(--blue-50);
    border: 1.5px solid var(--blue-400);
}

.alert-success-scheme {
    color: var(--green-400);
    background: var(--green-50);
    border: 1.5px solid var(--green-400);
}

/* Above 768px Desktop */
@media (min-width: 768px) {
    .scrollable {
        overflow-y: scroll;
    }

    .heading-text {
        font-style: normal;
        font-weight: bold;
        font-size: var(--heading-font);
        line-height: 29px;
    }
}

.noMessageBreak {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

/*.form-place-holder {*/
/*  height: 100vh;*/
/*}*/

/*.form-place-holder-hidden {*/
/*  height: 100vh;*/
/*}*/
.list-action-holder {
    width: 100%;
    display: flex;
    justify-content: end;
    text-align: right;
}

.react-datepicker__time-container--with-today-button {
    right: -85px !important;
}

/* Transitions*/
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
}


.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.whatsapp-green-stroke {
    stroke: #25D366;
}

.whatsapp-green-color {
    color: #25D366;
}

.disabled-calendar-action {
    color: var(--gray700) !important;
    cursor: not-allowed !important;
}

.dropdown-link {
    display: flex;
    /*background: red;*/
}