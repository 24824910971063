/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 11/09/23
 * Time: 10:20 am
 * Project: W-CRM-Frontend
 */
.componentWrapper-container {
    position: relative;
    min-height: 100px;
}

.componentWrapper-container.fill {
    all: inherit;
}

.componentWrapper-overlay {
    background: rgba(255, 255, 255, 0.55);
    height: 100%;
    width: 100%;
    z-index: 9;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--primary-padding);
    color: var(--primary-dark);
    backdrop-filter: blur(3px);
}
