/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 03/08/23
 * Time: 10:38 am
 * Project: W-CRM-Frontend
 */
:root {
    --childLayout-header-height: 50px;
    --childLayout-footer-height: 30px;
}

.sharedLayout-Container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clientLayout-container {
    display: flex;
    flex-direction: column;
    background: var(--white);
    min-height: 100vh;
    justify-content: center;
    height: max-content;
    align-items: center;
    gap: var(--primary-padding);
    padding-top: var(--primary-padding);
}

.clientLayout-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.clientLayout-header-holder {
    width: 100%;
    height: var(--childLayout-header-height);
    display: flex;
    align-items: center;
    justify-content: center;
}

.clientLayout-header-holder img {
    height: 100%;
}

.clientLayout-child-holder {
    min-height: 85vh;
    height: auto;
    display: flex;
    justify-content: center;
    background: white;
    padding: var(--primary-padding);
    border-radius: var(--primary-border-radius);
    width: 100%;
    overflow: auto;
}

.clientLayout-footer-holder {
    /*background: var(--white);*/
    padding: 4px;
    /*height: var(--childLayout-footer-height);*/
    width: 100%;
    display: flex;
    justify-content: center;
}

/* Code Here For Mobile Screen  */

/* Below 425px Mobile */
/* 425px - 768px Tablet */
@media (min-width: 425px) {
    /* Code Here For Tablet Screen  */
}

/* Above 768px Desktop */
@media (min-width: 768px) {
    /* Code Here For Desktop Screen  */
    .clientLayout-child-holder {
        width: calc(var(--a4-paper-width) + 100px);
    }
}

.embed-holder {
    background: transparent !important;
    padding-top: var(--primary-padding);
}

.embed-holder .card-parent-holder {
    background: transparent;
}

.embed-holder .appointmentDateSlotPicker-card-container {
    border: none;
}