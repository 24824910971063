/**
 * Created by Workaxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Date: 11/05/23
 * Time: 10:59 am
 * Project: W-CRM-Frontend
 */
.name-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--primary-border-radius);
    position: relative;
    overflow: clip;
}

.name-icon-container.small {
    height: 3.2em;
    width: 3.2em;
}

.name-icon-container.medium {
    height: 4em;
    width: 4em;
}

.name-icon-container.large {
    height: 5em;
    width: 5em;
}

.name-icon-character {
    font-weight: 400;
}


.name-icon-container.name-alert:after {
    width: 10px;
    height: 10px;
    content: " ";
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 100px;
    background: var(--green-700);
}