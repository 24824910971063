/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/2/2022
 * Time: 4:20 PM
 * Project: w_crm
 */

.input-component-border-red {
  border: solid rgb(252 165 165 / 1);
}

.input-component-default-input:focus {
  outline: none !important;
  border: solid var(--gray700);
}

.input-field-holder {
  display: inline;
  outline: none;
  border: 1px solid var(--gray200)!important;
  border-radius: var(--primary-border-radius);
  transition: background-color 0.5 ease-in-out;
  width: 100%;
  font-size: var(--default-font);
  /*padding: 10px 30px 10px 15px;*/
  /* padding-top: 10px;
      padding-bottom: 10px; */
}

.input-error-holder {
  opacity: 0;
  height: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}
.input-error-holder.active {
  opacity: 1;
}

.mobileNumberInput-container {
  display: flex;
}

.mobileNumberInput-code-container {
  max-width: 6.5em;
  min-width: 6em;
}
.mobileNumberInput-input-container {
  width: 100%;
}
.mobileNumberInput-input-wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.mobileNumberInput-code-wrapper {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
